import React from "react";

const Bridge = () => {
  return (
    <div className="container-div">
      <p>berachain is fake</p>
    </div>
  );
};

export default Bridge;
