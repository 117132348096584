import React from "react";
import { useAddress } from "@thirdweb-dev/react";

import Connected from "./Connected";
//

const Claim = () => {
  const connectedAddresss = useAddress();

  return !connectedAddresss ? (
    <div>
      <p className="p1">Are you worthy of being plugged?</p>
      <p className="p2">Connect wallet to find out</p>
    </div>
  ) : (
    <Connected />
  );
};

export default Claim;
