import React from "react";
import Confetti from "react-confetti";

const Claimed = () => {
  return (
    <div className="p1">
      <Confetti />
      You have claimed your $PLUG
    </div>
  );
};

export default Claimed;
