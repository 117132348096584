//if on allowlist allow to claim, if not show message saying not eligible
import React, { useState, useEffect } from "react";
import { useAddress, Web3Button } from "@thirdweb-dev/react";
import { parse } from "uuid";
import { bytesToHex } from "viem";
import Claimed from "./Claimed";
import "./pages.css";
import { CONTRACT_ADDRESS, UUID_LABEL } from "./addresses.js";
import { cardio } from "ldrs";

//
const bytesArray = parse(UUID_LABEL);
const hexId = bytesToHex(bytesArray);
//
const api =
  "https://hibxjljwpk.execute-api.us-east-1.amazonaws.com/serverless_lambda_stage/proof";

//
const Claimable = () => {
  const connectedAddresss = useAddress();
  const [result, setResult] = useState(null); // State to store the result
  const [expr, setExpr] = useState(""); // State to store the expression

  async function callAPI() {
    const response = await fetch(api, {
      method: "POST",
      body: JSON.stringify({
        address: connectedAddresss,
        uuid: UUID_LABEL,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    setResult(result);
    setExpr(
      //?
      result.canClaim
        ? "canClaim"
        : result.hasClaimed
        ? "hasClaimed"
        : "cantClaim"
    );
    console.log(result.amount);
    console.log(JSON.stringify(result.proof).slice(1, -1));
    console.log(result.canClaim);
  }

  useEffect(() => {
    if (connectedAddresss) {
      callAPI(); // Call the function when the component mounts and connectedAddresss is set
    }
  }, [connectedAddresss]); // Depend on connectedAddresss

  //canClaim, hasClaimed, cantClaim
  switch (expr) {
    case "canClaim":
      return (
        <div>
          <div className="p1">
            You are eligible for {JSON.stringify(result.amount).slice(1, -19)}{" "}
            $PLUG
          </div>
          <div className="claimText"> </div>

          <Web3Button
            className="button"
            contractAddress={CONTRACT_ADDRESS}
            action={async (contract) => {
              await contract.call("claimTokens", [
                hexId,
                result.proof,
                result.amount,
              ]);
            }}
          >
            Claim $PLUG
          </Web3Button>
        </div>
      );
    case "hasClaimed":
      return <Claimed />;

    default:
      return <cardio></cardio>;
  }
};
export default Claimable;
