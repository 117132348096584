import React, { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";

import Claimable from "./Claimable";
import { UUID_LABEL } from "./addresses.js";
import { cardio } from "ldrs";
import { HEDGEY_API } from "./addresses.js";

//
const Check = () => {
  const connectedAddresss = useAddress();
  const [result, setResult] = useState(null);

  useEffect(() => {
    async function callAPI() {
      const response = await fetch(HEDGEY_API, {
        method: "POST",
        body: JSON.stringify({
          address: connectedAddresss,
          uuid: UUID_LABEL,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setResult(data);
    }

    callAPI();
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  // Check the state of result and result.canClaim
  if (result) {
    return result.canClaim ? (
      <Claimable />
    ) : (
      <div className="p1">Yo bitchass aint gettting any free $PLUG</div>
    );
  }

  // Return null or a loading indicator while waiting for the API call to complete
  return cardio.register();
};

export default Check;
