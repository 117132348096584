import React from "react";

const Stats = () => {
  return (
    <div className="container-div">
      <p>stats coming soon</p>
    </div>
  );
};

export default Stats;
