import Check from "./Check";
import Claimed from "./Claimed";

import React from "react";
import { useContract, useAddress, useContractRead } from "@thirdweb-dev/react";
import { parse } from "uuid";
import { bytesToHex } from "viem";
import { CONTRACT_ADDRESS, UUID_LABEL } from "./addresses";

//
const bytesArray = parse(UUID_LABEL);
const hexId = bytesToHex(bytesArray);

const Connected = () => {
  const connectedAddresss = useAddress();

  const { contract } = useContract(CONTRACT_ADDRESS);
  const { data, isLoading, error } = useContractRead(contract, "claimed", [
    JSON.stringify(hexId).slice(1, -1),
    JSON.stringify(connectedAddresss).slice(1, -1),
  ]);

  return data ? (
    <div>
      <Claimed />
    </div>
  ) : (
    <Check />
  );
};

export default Connected;
